import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "box"
    }}>{`Box`}</h1>
    <p>{`Default box to to encapsulate some HTML. The applies the default `}<inlineCode parentName="p">{`box-shadow`}</inlineCode>{` and `}<inlineCode parentName="p">{`border-raduys`}</inlineCode>{` with a `}<inlineCode parentName="p">{`white`}</inlineCode>{` background`}</p>
    <div className='box' style={{
      "maxWidth": "300px"
    }}>
  <p className='black'>
    Some HTML here
  </p>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class='box'>
  <!-- Some HTML -->
</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      